@font-face {
  font-family: 'Bayon';
  letter-spacing: 20px;
  src: local('Bayon'), url(../public/fonts/Bayon.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Roboto', 'Inter', 'Helvetica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2F342C;
  
}

